<template>
  <div class="documents">
    <section>
      <div class="container">
        <div class="row">
          <div class="col-12 pt-5">
            <h2 class="text-center py-5 pt-md-0">Prochange UAB Terms and Conditions</h2>
            <p class="pb-3">These Terms and Conditions govern your use of service provided by Prochange UAB company
              (Reg.Nr. 305858194) whose registered address is Gedimino pr. 20, LT-01103 Vilnius, Lithuania. Prochange,
              UAB is authorized and regulated by the Lithuanian Financial Crime Investigation Service (FCIS). </p>
            <p class="pb-3">Prochange UAB provides services with the help of which a person exchanges a virtual currency
              against a fiat currency or a fiat currency against a virtual currency or a virtual currency against
              another virtual currency (hereinafter “The Services”). This User Agreement is governed by and construed in
              accordance with the laws of Lithuania.</p>
            <p class="pb-3">By registering to the Prochange UAB platform and using The Services, you agree that you have
              read, understood, and accepted all the Terms and Conditions of Prochange UAB. At any time Prochange UAB
              have the right to modify Terms and Conditions.</p>
            <h3 class="pb-3">1. Definitions</h3>
            <ol>
              <li class="pb-3"><b>Account Information</b> refers to a password and such information which may be
                provided by you as part of our security and verification procedures.
              </li>
              <li class="pb-3"><b>Business Day</b> refers to official working day of the week in Lithuania.</li>
              <li class="pb-3"><b>Company</b> refers to Prochange UAB</li>
              <li class="pb-3"><b>Crypto-to-crypto trading</b> refers to transactions in which one virtual currency is
                exchanged for another virtual currency.
              </li>
              <li class="pb-3"><b>Crypto-to-fiat trading</b> refers to transactions in which virtual currency is
                exchanged for fiat.
              </li>
              <li class="pb-3"><b>Digital Assets</b> refer to Virtual Currencies that exist or are stored in digital
                form.
              </li>
              <li class="pb-3"><b>Virtual/Digital currencies</b> shall mean a digital representation of value that does
                not possess a legal status of currency or money, that is not issued or guaranteed by a central bank or
                any other public authority, is not necessarily attached to a currency, but is accepted by natural
                or legal persons as a means of exchange and which can be transferred, stored and traded electronically.
              </li>
              <li class="pb-3"><b>Eligibility Criteria</b> refers to set of requirements that must be met.</li>
              <li class="pb-3"><b>Fiat-to-crypto trading</b> refers to transactions in which fiat is exchanged for
                virtual currency.
              </li>
              <li class="pb-3"><b>Services</b> refer to various services provided to you by Prochange that are based on
                Internet and/or blockchain technologies and offered via Prochange websites, platform and mobile
                application. Prochange Services include but are not limited to such ecosystem components as Digit Asset
                Exchange Platform, Wallets and Exchange services to be provided by Prochange.
              </li>
              <li class="pb-3"><b>Site/Platform</b> referts to <a
                  href="https://www.exchanity.com/">www.exchanity.com</a> website.
              </li>
              <li class="pb-3"><b>Exchanity</b> is Prochange UAB trading name.</li>
              <li class="pb-3"><b>Transaction Charges</b> refers to any fee charged by a bank/financial institution upon
                processing a valid refund request.
              </li>
              <li class="pb-3"><b>Users</b> refer to all individuals or organizations that access, download or use
                Prochange Services and are identified, verified and approved by Prochange Compliance Team.
              </li>
              <li class="pb-3"><b>Users Accounts</b> refer to an account that will be created for users use on the
                platform and users will be provided with required Account Information to enable access to the Account.
              </li>
            </ol>
            <h3 class="pb-3">2. User Agreement</h3>
            <p class="pb-3">These Terms of User Agreement (hereinafter “Agreement”) along with our Company Privacy
              Policy, constitutes a legally binding agreement between you, whether personally or on behalf of an entity
              and Prochange and its affiliated companies, websites, applications and tools, concerning your access to
              and use of the <a
                  href="https://www.exchanity.com/">www.exchanity.com</a> website as well as any other media form, media
              channel, mobile website or
              mobile application related or connected thereto.</p>
            <p class="pb-3">Company makes no representation that the Sites is appropriate or available in other
              locations other than where it is operated by Company. The information provided on the Sites is not
              intended for distribution to or use by any person or entity in any jurisdiction or country where such
              distribution or use would be contrary to law or regulation or which would subject Company to any
              registration requirement within such jurisdiction or country. Accordingly, those persons who choose to
              access the Sites from other locations do so on their own initiative and are solely responsible for
              compliance with local laws, if and to the extent local laws are applicable.</p>


            <h6 class="pb-3">2.1. User Account</h6>
            <p class="pb-3">An account will be created for your use on the Site, and you will be provided with required
              Account Information to enable your access to the User Account. To access any Services, you must activate
              your User Account by following the identity verification process. Failure to complete the User Account
              activation identification process as per the specified process will entitle the Company to terminate the
              said User Account. If you register on the Site through any third-party website, for example, Gmail, the
              login information of such third-party account shall be considered part of the Account Information. You
              must always treat Account Information as confidential and must not disclose it to any third party. Any
              access to them through your User Account shall be considered as access by you or on your behalf and you
              shall be solely responsible for any activity carried out in, by or through your User Account on the
              Site.</p>
            <h3 class="pb-3">
              3. Eligibility
            </h3>
            <p class="pb-3">
              By registering to use a Prochange User Account, you represent, consent and warrant that:
            </p>
            <ul>
              <li class="pb-3">as an individual, you are at least 18 or are of legal age to form a binding contract
                under applicable laws;
              </li>
              <li class="pb-3">as an individual, legal person, or other organization, you have full legal capacity and
                sufficient authorizations to enter these Terms.
              </li>
              <li class="pb-3">you have not been previously suspended or removed from using Prochange Services.</li>
              <li class="pb-3">you do not currently have a Prochange User Account.</li>
              <li class="pb-3">you are not located in, or a resident of any Restricted jurisdictions.</li>
              <li class="pb-3">you will not use the Prochange Services if any applicable laws in the User jurisdiction
                prohibit the User from doing so.
              </li>
              <li class="pb-3">you are a non-U.S. resident natural or legal person</li>
            </ul>
            <p class="pb-3">
              You are solely responsible for ensuring that You are compliant with these Terms of Use with all laws,
              rules, and regulations applicable to you. If your right to access the Services is revoked or use of the
              Services is in any way prohibited, in such circumstances, you agree not to use or access the Site and use
              the Services in any way;
            </p>
            <h3 class="pb-3">4. Prohibition of Use</h3>
            <p class="pb-3">Prochange UAB restrict and prohibit the use of our services from, and by residents or
              nationals of the following jurisdictions:</p>
            <p class="pb-3">
              Afghanistan,
              Albania,
              Barbados,
              Belarus,
              Botswana,
              Burkina Faso,
              Burma,
              Burundi,
              Cambodia,
              Cayman Islands,
              Central African Republic,
              Crimea,
              Cuba,
              Democratic People’s Republic of Korea (DPRK),
              Democratic Republic of the Congo,
              Ghana,
              Haiti,
              Iran,
              Iraq,
              Jamaica,
              Lebanon,
              Libya,
              Mali,
              Malta,
              Mauritius,
              Morocco,
              Myanmar,
              Nicaragua,
              North Korea,
              Pakistan,
              Palestine,
              Panama,
              Philippines,
              Russian Federation,
              Senegal,
              Somalia,
              South Sudan,
              Sudan,
              Syria,
              The Bahamas,
              Trinidad and Tobago,
              Uganda,
              United States of America,
              Vanuatu,
              Venezuela,
              Yemen,
              Zimbabwe
            </p>
            <p class="pb-3">
              By accessing and using Prochange services, you represent, consent and warrant that you have not been
              included in any economic sanctions list, the list of specially designated nationals maintained by OFAC
              (the office of foreign assets control of the U.S. department 7of the treasury), or the denied persons or
              entity list of the U.S. department of commerce,  any EU or UN sanction list of legal and natural persons.
              Prochange UAB reserves the right to choose markets and
              jurisdictions to conduct business with.
            </p>
            <h3 class="pb-3">
              5. Access to the Site
            </h3>
            <p class="pb-3">We do not guarantee that your use of the Site will always be available or be uninterrupted.
              We may suspend, withdraw, discontinue, or change all or any part of the Site without notice. We will not
              be liable to you including without limitation for any losses incurred due to the volatility of prices of
              the Digital Asset if for any reason the Site is unavailable at any time or for any period.</p>
            <p class="pb-3">
              You are also responsible for ensuring that all persons who access the Site through your internet
              connection are aware of these Terms and Conditions of Prochange and other applicable terms and conditions
              and that they comply with them.
            </p>
            <h3 class="pb-3">
              6. Liability
            </h3>
            <p class="pb-3">
              Users are responsible for their actions in connection with the use of the Site, service, and their
              Accounts. Users are also responsible for protecting access information to the Site including, but not
              limited to, usernames, passwords, and bank account details. The Company is not responsible for the
              outcome, whether positive or negative, of any action performed by any of its users within or related to
              the Site. Some deposits and withdrawal methods require the Company to use personal details of the user
              including, but not limited to, name, address, email, phone number, and bank account number, etc. and you
              agree that Company can use this information to facilitate deposits and withdrawals. The Company shall be
              in no manner be liable for any transaction failure or loss caused due to any fault on part of the User
              including for the User providing any wrong information or detail. In the event the User deliberately
              provides false information and/or provides any misrepresentation and/or attempts to create false
              identity/ID and/or impersonate any other person and/or attempts to breach the Prochange Terms and
              Conditions, the User shall be liable to indemnify the Company and the Company may take all necessary legal
              actions against the User, including any criminal action.
            </p>
            <p class="pb-3">
              The parties agree that in no event shall the Company or its affiliates, officers, directors, agents,
              employees, and suppliers be liable for lost profits or any special, incidental, or consequential damages
              arising out of or in connection with the use of our Site, Service, or these Terms and Conditions. In case
              the applicable laws do not permit the limitation or exclusion of damages as described above, our aggregate
              liability (including the liability of our affiliates, officers, directors, agents, employees, and
              suppliers) in respect of claims based on events arising out of or in connection with any single member’s
              use of the Website, whether in contract or tort or otherwise, shall in no circumstances exceed the amount
              of the transaction(s) that are the subject of the claim less any amount of commission that may be due and
              payable in respect of such transaction(s).
            </p>
            <p class="pb-3">
              We have no control over, or liability for, the delivery, quality, safety, legality, or any other aspect of
              Digital Assets that You may purchase or sell to or from a third party (including other users of the
              Services). We are not responsible for ensuring that a third-party buyer or seller You transact with on the
              Site will complete a transaction or is authorized to do so. We shall not be liable or responsible for any
              risk associated with such third-party transaction, including any issue that may arise in respect of
              banking transactions that You may undertake in pursuance thereof.
            </p>
            <h3 class="pb-3">
              7. Identification
            </h3>
            <p class="pb-3">You agree to provide us with the information we request (that we may request at any time
              deemed necessary) for the purposes of identity verification and the detection of money laundering,
              terrorist financing, fraud, or any other financial crime and permit us to keep a record of such
              information. You are required to successfully complete Identification and Verification procedure via
              third-party AML/KYC provider Sum and Substance.</p>
            <p class="pb-3">Prochange in some cases is obliged to perform person's verification with information
              technology means and the Client and its representative agrees to the conditions of identification of a
              person and verification of person's identity with information technology means (which includes necessity
              to use video camera with appropriate resolution and light level, show Your identification documents and
              perform other necessary activities, if requested). The Client and its representative also agrees that his
              or her personal data will be processed and recorded in the course of the procedures of such identity
              verification and that such procedures are performed by third party specifically assigned by the
              Prochange.</p>
            <p class="pb-3">The information we request may include (but is not limited to) personal information such as
              your name, residential address, telephone number, email address, date of birth, taxpayer identification
              number, government identification number, information regarding your bank account (such as the name of the
              bank, the account type, routing number, and account number) network status, customer type, customer role,
              billing type, mobile device identifiers (e.g. international mobile subscriber identity and international
              mobile equipment identity) and other subscriber status details, and any such information that Prochange is
              required to collect from time to time under applicable law.</p>
            <p class="pb-3">If you are a legal person, we may request the following:
            </p>
            <ul>
              <li class="pb-3">registered address</li>
              <li class="pb-3">names and identity of beneficial owners</li>
              <li class="pb-3">names and identity of directors</li>
              <li class="pb-3">names and identity of the persons having the right to act on behalf of a customer
              </li>
              <li class="pb-3">names and identity of the account users</li>
              <li class="pb-3">organisational structure leading to the ultimate beneficial owner</li>
              <li class="pb-3">source of funds</li>
            </ul>
            <p class="pb-3">For the Customer’s directors, beneficial owners and other natural
             persons acting on behalf of the customer, the person must provide an identification
               document with the following:
              </p>

            <ul>
              <li class="pb-3">name/names;
              </li>
              <li class="pb-3">surname/surnames;
              </li>
              <li class="pb-3">personal number (in the case of a foreigner – date of birth (where available –
                personal number or any other unique sequence of symbols granted to that person, intended for
                personal identification), the number and period of validity of the residence permit in the Republic of
                Lithuania and the place and date of its issuance (applicable to foreigners);
              </li>
              <li class="pb-3">photograph;
              </li>
              <li class="pb-3">signature (except for the cases where it is optional in the identification document);
              </li>
              <li class="pb-3">citizenship (except for the cases where it is optional in the identification document) and in the case of a stateless person – the state which issued his identification document.
              </li>
            </ul>

            <p class="pb-3">You may also be required to undergo “Enhanced Due Diligence”, where Prochange may request
              that you submit additional information about yourself or your business, provide relevant records, and
              arrange for meetings with Prochange staff so that Prochange may, among other things, establish the source
              of your wealth and source of funds for any transactions carried out in the course of your use of Prochange
              Services.</p>
            <p class="pb-3">In providing us with this or any other information that may be required, you confirm that
              the information is true, accurate and complete, and you have not withheld any information that may
              influence Prochange evaluation of you for the purposes of your registration for a Prochange Account or the
              provision of Prochange Services to you. You undertake to promptly notify in writing and provide Prochange
              with information regarding any changes in circumstances that may cause any such information provided to
              become false, inaccurate or incomplete and undertake to provide any other additional documents, records
              and information as may be required by Prochange and/or applicable law. You permit us to keep records of
              such information.</p>
            <p class="pb-3">You authorise us to make enquiries, whether directly or through third parties, that we
              consider necessary to verify your identity or protect you and/or us against fraud or other financial
              crime, and to act we reasonably deem necessary based on the results of such enquiries. When we carry out
              these enquiries, you acknowledge and agree that your personal information may be disclosed to credit
              reference and fraud prevention or financial crime agencies and that these agencies may respond to our
              enquiries in full. This is an identity check only and should have no adverse effect on your credit rating.
              Additionally, we may require you to wait some amount of time after completion of a transaction before
              permitting you to use further Prochange Services and/or before permitting you to engage in transactions
              beyond certain volume limits.</p>
            <h3 class="pb-3">
              8. Payments & Transactions
            </h3>
            <p class="pb-3">By making use of Prochange Services, you acknowledge and agree that:</p>
            <ol>
              <li class="pb-3"> you are aware of the risks associated with transactions of digital currencies and other
                derivatives
              </li>
              <li class="pb-3"> you shall assume all risks related to the use of Prochange services and transactions of
                digital currencies; and
              </li>
              <li class="pb-3"> Prochange shall not be liable for any such risks or adverse outcomes.</li>
            </ol>
            <p class="pb-3">The use of all Prochange Services is subject to a limit on the volume, stated in EUR or
              other fiat currency or Digital Currency, you may transact or transfer in a given period (e.g., daily,
              monthly). Your transaction limits may vary depending on your payment method, verification steps you have
              completed, and other factors. We reserve the right to change applicable limits as we deem necessary. If
              you wish to raise your limits beyond the posted amounts, you may submit a request to
              <a href="mailto:support@exchanity.com">support@exchanity.com</a></p>
            <p class="pb-3">Prochange supports the following top up methods:</p>
            <p class="pb-3">a) Fiat:</p>
            <ul>
              <li class="pb-3">Bank Transfers (SEPA)</li>
              <li class="pb-3">Card Transfers (VISA/MASTERCARD)</li>
            </ul>
            <p class="pb-3"><i>* With these Terms and Conditions, you consent and warrant that bank and card transfers
              will be made only from your own bank account. The name and surname must match with the name registered on
              Prochange User Account.</i></p>
            <p class="pb-3">b) External Wallet</p>
            <p class="pb-3">
              * With these Terms and Conditions, you consent and warrant that external cryptocurrency wallet belongs to
              you and are registered within your name.
            </p>
            <p class="pb-3">
              We may require you to submit additional information about yourself or your business, provide records, and
              arrange for meetings with Prochange staff if you wish to raise your limits ("Enhanced Due Diligence"). In
              our discretion, we may refuse to raise your limits, or we may lower your limits at a subsequent time even
              if you have completed Enhanced Due Diligence.
            </p>
            <h3 class="pb-3">9. Resolution of Disputes & Applicable law</h3>
            <ul>
              <li class="pb-3">Prochange and the User agree that any disputes arising between them shall initially be
                attempted to be resolved by mutual negotiations. In such a case, the User shall contact. Prochange by
                sending an email describing the dispute to the <a href="mailto:support@exchanity.com">support@exchanity.com</a>
                Prochange will contact the User via
                the email that was used for registration or any other information channel that the User provided to
                Prochange in order to access the Platform.
              </li>
              <li class="pb-3">The provisions of the Services shall be governed by and interpreted in accordance with
                Lithuanian Law and the parties submit to the exclusive jurisdiction of the Lithuanian Court.
              </li>
              <li class="pb-3">If You experience a problem in buying or selling Digital Assets from or to a third party
                or If You believe a third party has behaved in a fraudulent, misleading, or inappropriate manner, you
                may notify us via <a href="mailto:support@exchanity.com">support@exchanity.com</a> so that We may
                consider what action to take. Our decision in
                relation to such dispute shall be final and binding on You.
              </li>
              <li class="pb-3">Notwithstanding anything contained herein, we reserve the right to decide if a dispute
                exists in relation to any transaction undertaken on the Site by You with a third party and our decision
                in relation to the same shall be final and binding on You.
              </li>
            </ul>
            <h3 class="pb-3">10. Copyrights and Other Intellectual Property Rights</h3>
            <p class="pb-3">Unless otherwise indicated by us, all copyright and other intellectual property rights in
              all content and other materials contained on our website or provided in connection with the Services,
              including, without limitation, the Prochange logo and all designs, text, graphics, pictures, information,
              data, software, sound files, other files and the selection and arrangement thereof (collectively "
              Prochange Materials") are the proprietary property of Prochange UAB or our licensors or suppliers.</p>
            <p class="pb-3">We hereby grant you a limited, nonexclusive and non-sublicensable license to access and use
              the Prochange Materials for your personal or internal business use. Such license is subject to these Terms
              and does not permit</p>

            <ol>
              <li class="pb-3"> any resale of the Prochange Materials.</li>
              <li class="pb-3"> the distribution, public performance or public display of any Prochange Materials.</li>
              <li class="pb-3"> modifying or otherwise making any derivative uses of the Prochange Materials, or any
                portion thereof; or
              </li>
              <li class="pb-3"> any use of the Prochange Materials other than for their intended purposes. The license
                granted under this Section will automatically terminate if we suspend or terminate your access to the
                Services.
              </li>
            </ol>
            <h3 class="pb-3">11. Amendment and Changes</h3>
            <p class="pb-3">We may revise these Terms and Conditions at any time by amending this page. Please check
              this page from time to time to take notice of any changes we made, as they are binding on you.</p>
            <p class="pb-3">We may, without prior notice, change the Services; add or remove functionalities or
              features; stop providing the Services or features of the Services, to you or to Users generally; or create
              usage limits for the Services.</p>
            <h3 class="pb-3">
              12. Taxes
            </h3>
            <p class="pb-3">
              It is Your responsibility to determine, collect, report, and submit any taxes applicable to the payments
              You make or receive in connection with the use of the Site and the Service to the appropriate tax
              authority. The Company is not responsible for determining whether taxes apply to your transaction, or for
              collecting, reporting or remitting any taxes arising from any transaction.
            </p>
            <h3 class="pb-3">
              13. Fees
            </h3>
            <p class="pb-3">
              You agree to pay Prochange the fees as per live rate. Prochange may, in its discretion, update the fees at
              any time. Any updated fees will apply to any sales or other Transactions that occur following the
              effective date of the updated fees. You authorize Prochange to deduct from your account any applicable
              fees that you owe under these Terms. Fees are calculated as a percentage of the trade's quote currency
              volume (by default).
            </p>
            <p class="pb-3">
              Additional fees might be applicable for corporate customers that will be determined separately from this
              User Agreement.
            </p>
            <h3 class="pb-3">
              14. Suspension, Cancellation, and Termination
            </h3>
            <p class="pb-3">
              You may cancel services and/or terminate this agreement with the Prochange, and close your User Account at
              any time, following the settlement of any pending transactions.
            </p>
            <p class="pb-3">
              You agree that Prochange may, by giving notice, in its sole discretion, terminate your access to the Site
              and to your User Account; including without limitation, suspending or terminating the Service and Users’
              Accounts, prohibiting access to the Site and its content, services and tools, delaying or removing hosted
              content, and taking technical and legal actions to keep Users off the Site if we reasonably perceive that
              such Users are creating any problems, including without limitation, possible legal liabilities,
              infringement of the intellectual property rights, or acting inconsistently with the letter or spirit of
              these Terms of Conditions.
            </p>
            <p class="pb-3">
              Notwithstanding the above, we may, in appropriate circumstances and at our sole discretion, suspend or
              terminate Accounts of Users for any reason, including without limitation
            </p>
            <ol>
              <li class="pb-3">
                attempts to gain unauthorized access to the Site or another User’s account or providing assistance to
                others’ attempting to do so,
              </li>
              <li class="pb-3">
                overcoming software security features limiting use of or protecting any content,
              </li>
              <li class="pb-3">
                usage of the Service to perform illegal activities such as money laundering, illegal gambling
                operations, financing terrorism, or other criminal activities,
              </li>
              <li class="pb-3">
                violations of these Terms and Conditions,
              </li>
              <li class="pb-3">
                failure to pay or fraudulent payment for transactions,
              </li>
              <li class="pb-3">
                unexpected operational difficulties, or
              </li>
              <li class="pb-3">
                upon the request of any governmental authority, if deemed to be legitimate and compelling by the
                Company, acting in its sole discretion.
              </li>
            </ol>
            <p class="pb-3">
              We also reserve the right to cancel unverified Accounts or Accounts that have been inactive for a period
              of 6 months or more, and/or to modify or discontinue our Site or Service. Users agree that the Company
              will not be liable to them or to any third party for termination of their Account or access to the Site.
            </p>
            <p class="pb-3">
              You hereby agree that the suspension of an Account shall not affect the payment of the commissions due for
              past transactions. Upon termination, Users will receive any monies owed to them via their registered bank
              account with the Company.
            </p>
            <p class="pb-3">
              We may suspend, restrict, or terminate your access to any or all of the Prochange Services and/or
              deactivate or cancel your Prochange Account without reason. You acknowledge that our decision to take
              certain actions, including limiting access to, suspending, or closing your Prochange Account, may be based
              on confidential criteria that are essential for the purposes of our risk management and security
              protocols. You agree that Prochange is under no obligation to disclose the details of its risk management
              and security procedures to you.
            </p>
            <h3 class="pb-3">
              15. Risks
            </h3>
            <p class="pb-3">
              A considerable loss may be sustained/incurred while trading in Prochange, hence you are advised to
              determine your financial circumstances and risk tolerance before trading and carefully consider the
              following risks: Digital Assets are currently unregulated, holding and transferring some or all Digital
              Assets may be deemed illegal in the future. You are encouraged to obtain appropriate legal counsel
              regarding the same before using the Site. ‘Virtual currencies’ (including all cryptocurrencies) have not
              been recognized or authorized as a medium for payment, as a commodity for trade, or in any form, by the
              central bank, monetary authority, or governmental or regulatory body. The value of any Digital Asset is
              very volatile, and you may sustain a total loss of your Funds. Digital Assets are not backed by a central
              bank or any other financial regulator and as such, there is no third party that may take any corrective
              action upon the occurrence of a global/regional crisis. Since Digital Assets are held online, they are
              susceptible to security breaches and government crackdowns that may end up compromising the integrity or
              anonymity of the system that produces such Digital Assets.
            </p>
            <p class="pb-3">
              You acknowledge that the is an inexhaustive statement of risks associated with the trade of Digital Assets
              and that there may be additional risks not listed or foreseen by the Company. By creating User Account,
              you acknowledge that you have carefully assessed your financial standing and risk tolerance and you
              confirm that the same is suitable for trading in Digital Assets.
            </p>
            <h3 class="pb-3">
              16. Communication
            </h3>
            <p class="pb-3">
              When you visit the Site or send e-mails to us, you are communicating with us electronically. You also
              consent to receive communications from us electronically. We will communicate with you by e-mail or by
              posting notices on this Site. You agree that all agreements, notices, disclosures and other communications
              that we provide to you electronically satisfy any legal requirement that such communications be in
              writing. You agree that in case there are any changes in any information provided by you to us, including
              your e-mail address and other contact details, you will be solely responsible to update them regularly. If
              we send any communication by e-mail, it shall be sent to your e-mail address available on the records
              created by you on the Site and the App and it shall be deemed to have been received by you once it is
              reflected assent in the outbox of our e-mail id.
            </p>
            <h3 class="pb-3">
              17. Assignment
            </h3>
            <p class="pb-3">
              You acknowledge and agree that the Company can freely assign these Terms of Use or any of its rights under
              these Terms of Use to any person without your consent. You shall, however, not be entitled to assign these
              Terms of Use, in whole or in part, to another person without the prior written approval of the Company.
            </p>


          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "UserAgreement",
  mounted() {
    document.querySelector('body').scrollIntoView({
      behavior: 'smooth'
    });
  }
}
</script>
<style scoped>
li {
  margin-left: 30px;
}

</style>
